

.mainSection {
    display: flex;
    flex-direction: column;
    width: 750px;
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 30px;
    margin-right: 30px;
    border-radius:  20px;
    background-color: #141313;
    justify-content: space-between;
    -webkit-border-radius:  20px;
    -moz-border-radius:  20px;
    -ms-border-radius:  20px;
    -o-border-radius:  20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
        h1{
            text-align: center;
            a{
                text-decoration: none;
            }
        }
        img{
            display: block;
            margin-left: auto;
            margin-right: auto;
            
        }
        .button{
            display: block;
            margin-left: auto;
            margin-right: auto;
            font-size: 25px;
            font-weight: 700;
            width: 200px;
            height: 50px;
            // background-color: #440b29;
            background-color: #0c0b44;
            border: 2px solid #bd925e;
            border-radius: 20px;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            cursor: pointer;
        }
        .window{
            display: flex;
            margin: 0 auto;
            list-style: none;
            justify-content: center;
            .circle {
                display: flex;
                margin-left: 20px;
                margin-right: 20px;
                width: 100px;
                height: 100px;
                border-radius: 100px;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                -ms-border-radius: 100px;
                -o-border-radius: 100px;
                box-shadow: 0 0 10px rgba(0,0,0,0.5);
            }
        }
}
.button_onClick{
    transition: 200ms ease-in-out;
    transform: scale(0.96);
}

.spells__group{
    display: flex;
    justify-content: space-around;
    .square{
        display: flex;
        width: 100px;
        height: 100px;
        span{
            margin-left: 70px;
            position: absolute;
            z-index: 1;
            font-size: 18px;
            width: 1px;
            height: 1px;
        }
        img{
            margin: 0;
            position: relative;
            width: 90px;
            height: 90px;
        }
    }
}

.controll__list{
    img{
        width: 70px;
        
    }
    p{  
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 20px;
        font-size: 18px;
        
    }
}

.spell__list{
    img{
        width: 50px;    
    }
    li{ 
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
    }
    p{  
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 20px;
        font-size: 18px;
        
    }
}

