

.mainSection {
    // width: 750px;
    // height: 750px;
    // border-radius:  20px;
    // background-color: #141313;
    // -webkit-border-radius:  20px;
    // -moz-border-radius:  20px;
    // -ms-border-radius:  20px;
    // -o-border-radius:  20px;
    // box-shadow: 0 0 10px rgba(0,0,0,0.5);
        h1{
            text-align: center;
        }
        img{
            display: block;
            margin-left: auto;
            margin-right: auto;
            
        }

        .window{
            // display: flex;
            // margin: 0 auto;
            // margin-top: 50px;
            // list-style: none;
            // justify-content: center;
            .circle {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 20px;
                margin-right: 20px;
                width: 100px;
                height: 100px;
                border-radius: 100px;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                -ms-border-radius: 100px;
                -o-border-radius: 100px;
                box-shadow: 0 0 10px rgba(0,0,0,0.5);
                img{
                    margin: 0;
                    width: 90px;
                    height: 90px;
                    border-radius: 90px;                    
                }
            }
        }
}




.spells__group{
    display: flex;
    justify-content: space-around;
    .square{
        display: flex;
        width: 110px;
        height: 110px;
        border: 5px solid #1d1c1c;
        border-style: groove;
        padding: 5px;
        span{
            margin-left: 70px;
            position: absolute;
            z-index: 1;
            width: 1px;
            height: 1px;
        }
        img{
            margin: 0;
            position: relative;
            width: 90px;
            height: 90px;
        }
    }
}

.controll__list{
    img{
        width: 70px;
        
    }
    p{  
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 20px;
        font-size: 18px;
        
    }
}

.spell__list{
    img{
        width: 50px;    
    }
    li{ 
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
    }
    p{  
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 20px;
        font-size: 15px;
        
    }
}

.curent__spell{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    height: 100px;
    background-color: #141313;
    border-radius:  20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    font-size: 25px;
    img{
        width: 80px;
        margin: 0 !important; 
    }

}

.restart{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    height: 40px;
    background-color: #141313;
    border-radius:  20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.restart:hover{
    transition: background 200ms ease-in-out;
    background-color: #1d1c1c;
}